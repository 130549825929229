// src/App.js

import React, { useState, useEffect } from "react";
import styled, { keyframes, ThemeProvider as StyledThemeProvider } from "styled-components";
import { ThemeProvider } from "./contexts/ThemeContext";
import { AuthProvider } from "./contexts/AuthContext";
import Routes from "./routes";
import { lightTheme } from "./styles/theme";

// The site key - you can change this to any value you prefer
const SITE_KEY = process.env.REACT_APP_SITE_ACCESS_KEY;

// Animations
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background-color: ${props => props.theme.colors.background};
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.body};
`;

const LogoText = styled.h1`
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: ${props => props.theme.fonts.heading};
  text-align: center;
  animation: ${fadeIn} 0.8s ease-out;

  span:first-child {
    color: ${props => props.theme.colors.primary};
  }

  span:last-child {
    color: ${props => props.theme.colors.secondary};
  }
`;

const GlassCard = styled.div`
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 40px;
  width: 100%;
  max-width: 480px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.15);
  margin-top: 30px;
  animation: ${fadeIn} 0.8s ease-out 0.2s backwards;
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 16px;
  color: ${props => props.theme.colors.text};
  font-family: ${props => props.theme.fonts.body};
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 16px;
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: ${props => props.theme.fonts.body};

  &:hover {
    background-color: ${props => props.theme.colors.primaryLight};
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ErrorText = styled.p`
  color: ${props => props.theme.colors.error};
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
  font-family: ${props => props.theme.fonts.body};
  animation: ${fadeIn} 0.3s ease-out;
`;

const Caption = styled.p`
  font-size: 18px;
  color: ${props => props.theme.colors.textLight};
  text-align: center;
  max-width: 600px;
  margin: 0 auto 30px;
  animation: ${fadeIn} 0.8s ease-out 0.1s backwards;
`;

function SiteLock({ children }) {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [key, setKey] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const unlocked = localStorage.getItem("siteLockUnlocked");
    if (unlocked === "true") {
      setIsUnlocked(true);
    }
  }, []);

  const handleUnlock = () => {
    if (key === SITE_KEY) {
      setIsUnlocked(true);
      localStorage.setItem("siteLockUnlocked", "true");
      setError("");
    } else {
      setError("Invalid access code. Please try again.");
    }
  };

  if (!isUnlocked) {
    return (
      <StyledThemeProvider theme={lightTheme}>
        <Container>
          <LogoText>
            <span>Research</span>
            <span>Ark</span>
          </LogoText>
          <Caption>
            Unlock tailored research grants and funding sources with our AI-powered platform
          </Caption>
          <GlassCard>
            <Input
              type="password"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleUnlock()}
              placeholder="Enter access code"
            />
            {error && <ErrorText>{error}</ErrorText>}
            <Button onClick={handleUnlock}>
              Unlock Access
            </Button>
          </GlassCard>
        </Container>
      </StyledThemeProvider>
    );
  }

  return children;
}

function App() {
  return (
    <SiteLock>
      <ThemeProvider>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </ThemeProvider>
    </SiteLock>
  );
}

export default App;
